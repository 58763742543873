import React, { useContext, useState } from "react"
import { CentralCurrentUsersEntriesQuery_currentUser } from "../../../../../../__generated__/CentralCurrentUsersEntriesQuery"
import { UpsertPoolMessageMutationVariables } from "../../../../../../__generated__/UpsertPoolMessageMutation"
import { DeleteReasonKeyEnum } from "../../../../../../common/enums"
import LoadingView from "../../../../../components/LoadingView"
import Modal, { ModalWrapper } from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"
import { RemoveMessagesMutation, RemoveMessagesMutationVariables } from "../../../../../../__generated__/RemoveMessagesMutation"
import { useMutation } from "@apollo/client/react/hooks"
import { REMOVE_POOL_MESSAGES } from "../../../../queries"
import GenericConfirmationModal from "../../../components/EntryModals/GenericConfirmationModal"
import { toast } from "@cbs-sports/sports-shared-client/build/cjs/components/Toast/toast"
import { extractValidationError } from "../../../../../../common/apiErrors"
import styled from "styled-components/macro"
import { fontFamily, fontWeight, newPalette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import CloseSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Close"
import PoolDataContext, { PoolDataContextType } from "../../../../../Contexts/PoolDataContext"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { PoolMessageBoardQuery_message, PoolMessageBoardQuery_pool_messages_edges_node } from "../../../../../../__generated__/PoolMessageBoardQuery"
import { FetchResult, MutationFunctionOptions } from "@apollo/client"
import { IPoolData } from "../../../../../../routes.d"

const MessageForm = React.lazy(() => import("../../../containers/MessageBoard/MessageForm"))

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  box-sizing: border-box;
  padding: 0 1rem;
  width: 100%;
  background-color: ${newPalette.gray97};
  border-bottom: 1px solid ${newPalette.gray90};

  > svg {
    width: 1.5rem;
    height: 1.5rem;
    color: ${newPalette.gray50};
    cursor: pointer;
  }

  & > .title {
    display: flex;
    height: 1rem;
    font-family: ${fontFamily.base};
    font-size: 1rem;
    font-style: normal;
    font-weight: ${fontWeight.bold};
    line-height: 1.5rme;
    letter-spacing: -0.1px;
    text-align: left;
    color: ${newPalette.gray40};
  }
`

export interface IMessageFormContext {
  hideMessageForm: () => void
  hideDeleteMessageForm: () => Promise<void> | void
  showDeleteMessageForm: (message: PoolMessageBoardQuery_pool_messages_edges_node) => Promise<void> | void
  showMessageForm: (isReply?: boolean, quoting?: PoolMessageBoardQuery_pool_messages_edges_node) => void
  isReply: boolean
  upsertPoolMessageMutationVariables: UpsertPoolMessageMutationVariables
  quoteMessage?: PoolMessageBoardQuery_pool_messages_edges_node
  deletingMessage?: PoolMessageBoardQuery_pool_messages_edges_node
  currentPlayer?: IPoolData["detailedMember"]
  currentUser?: CentralCurrentUsersEntriesQuery_currentUser
  rootMessage?: PoolMessageBoardQuery_message | null
  refreshQuery: () => Promise<void>
  newMessageCallBack?: (isReply?: boolean) => Promise<void> | void
  removeMessageMutation: (
    options?: MutationFunctionOptions<RemoveMessagesMutation, RemoveMessagesMutationVariables> | undefined,
  ) => Promise<FetchResult<RemoveMessagesMutation>>
}

export const MessageFormContext = React.createContext<IMessageFormContext>({
  hideMessageForm: () => undefined,
  showMessageForm: () => undefined,
  showDeleteMessageForm: () => undefined,
  hideDeleteMessageForm: () => undefined,
  isReply: false,
  quoteMessage: undefined,
  rootMessage: undefined,
  currentUser: undefined,
  refreshQuery: () => Promise.resolve(undefined),
  newMessageCallBack: () => undefined,
  removeMessageMutation: (options?: MutationFunctionOptions<RemoveMessagesMutation, RemoveMessagesMutationVariables> | undefined) =>
    Promise.resolve({}),
  upsertPoolMessageMutationVariables: {
    poolId: "",
    rootMessageId: undefined,
    body: "",
    htmlBody: "",
  },
})

interface IMessageFormProvider {
  children: React.ReactNode
  upsertPoolMessageMutationVariables: UpsertPoolMessageMutationVariables
  currentUser?: CentralCurrentUsersEntriesQuery_currentUser
  rootMessage?: PoolMessageBoardQuery_message | null
  loading?: boolean
  apiRefresherFn: () => void
  newMessageCallBack?: (isReply?: boolean) => Promise<void> | void
}
export const MessageFormProvider = ({
  children,
  upsertPoolMessageMutationVariables,
  currentUser,
  rootMessage,
  loading,
  apiRefresherFn,
}: IMessageFormProvider) => {
  const [showForm, setShowForm] = useState(false)
  const [showDeleteForm, setShowDeleteForm] = useState(false)
  const [isReply, setIsReply] = useState<boolean>(false)
  const [quoteMessage, setQuoteMessage] = useState<PoolMessageBoardQuery_pool_messages_edges_node | undefined>(undefined)
  const [deletingMessage, setDeletingMessage] = useState<PoolMessageBoardQuery_pool_messages_edges_node | undefined>(undefined)
  const poolDataContext: PoolDataContextType = useContext(PoolDataContext)
  const { poolId, detailedMember: currentPlayer } = poolDataContext || emptyObject

  const [removeMessageMutation] = useMutation<RemoveMessagesMutation, RemoveMessagesMutationVariables>(REMOVE_POOL_MESSAGES, {
    refetchQueries: ["PoolMessageBoardQuery"],
  })

  const showMessageForm = (replyingTo: boolean | undefined, quoting?: PoolMessageBoardQuery_pool_messages_edges_node) => {
    setQuoteMessage(quoting)
    setIsReply(replyingTo || false)
    setShowForm(true)
  }

  const hideMessageForm = () => {
    setShowForm(false)
    setTimeout(() => {
      setQuoteMessage(undefined)
    }, 800)
    setIsReply(false)
  }

  const showDeleteMessageForm = (message: PoolMessageBoardQuery_pool_messages_edges_node) => {
    setDeletingMessage(message)
    setShowDeleteForm(true)
  }

  const hideDeleteMessageForm = () => {
    setShowDeleteForm(false)
  }

  const refreshQuery = async () => {
    if (typeof apiRefresherFn === "function") {
      apiRefresherFn()
    }
  }

  const onDeleteMessage = () => {
    const variables: RemoveMessagesMutationVariables = {
      reason: DeleteReasonKeyEnum.OTHER,
      messageIds: deletingMessage ? [deletingMessage.id] : [],
      poolId,
    }

    removeMessageMutation({ variables })
      .then((_res) => {
        toast.snackbar("Message deleted")
      })
      .catch((err) => {
        const apiErrors = extractValidationError(err)
        toast.snackbar(apiErrors.message)
      })
      .finally(hideDeleteMessageForm)
  }

  const providerValue = {
    hideMessageForm,
    showMessageForm,
    refreshQuery,
    showDeleteMessageForm,
    hideDeleteMessageForm,
    isReply,
    quoteMessage,
    deletingMessage,
    currentUser,
    rootMessage,
    loading,
    upsertPoolMessageMutationVariables: {
      ...upsertPoolMessageMutationVariables,
      rootMessageId: isReply ? upsertPoolMessageMutationVariables.rootMessageId : undefined,
      poolId,
    },
    removeMessageMutation,
    currentPlayer,
  }

  return (
    <MessageFormContext.Provider value={providerValue}>
      {children}
      <Modal isOpen={showForm} padded={false}>
        <ModalWrapper modalType="dialog" variant="white" padded={false}>
          <ModalHeader>
            <span className="title">{isReply ? "Reply To Topic" : "New Topic"}</span>
            <CloseSvg onClick={hideMessageForm} />
          </ModalHeader>
          <React.Suspense fallback={<LoadingView />}>
            <MessageForm isReply={isReply} deviceType={poolDataContext?.deviceType} />
          </React.Suspense>
        </ModalWrapper>
      </Modal>
      <GenericConfirmationModal
        isOpen={showDeleteForm}
        onConfirm={onDeleteMessage}
        onClose={hideDeleteMessageForm}
        title="Delete message?"
        message="You can't undo this action."
        ctaButtonLabel="Delete"
      />
    </MessageFormContext.Provider>
  )
}

export default MessageFormProvider
