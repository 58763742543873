import { useQuery } from "@apollo/client"
import React from "react"
import styled from "styled-components/macro"
import { PoolMessageBoardQuery, PoolMessageBoardQueryVariables } from "../../../../../__generated__/PoolMessageBoardQuery"
import { TPoolRouteProps } from "../../../../../routes.d"
import ApiRefresher from "../../../../Base/ApiRefresher"
import { OpacityBtn } from "../../../../components/Button"
import LockSvg from "../../../../components/icons/Lock"
import LoadingView from "../../../../components/LoadingView"
import useControls from "../../../../hooks/useControls"
import { edgeToNode } from "../../../../utils/misc-utils"
import { fontWeight, palette, pxToRem } from "../../../../utils/style-utils"
import { getStringParam, setParam } from "../../../../utils/url-utils"
import { POOL_MESSAGE_BOARD_QUERY } from "../../../queries"
import { StandardPoolPageContainer } from "../../components/PoolPage"
import { MessageFormProvider } from "./Context/MessageFormContext"
import MessagesContainer from "./MessagesContainer"
import PoolInnerWithSideCol from "../../components/InnerWithSideCol"
import MultipleEntriesMessageBoard from "../MultipleEntriesArea/MessageBoard/MultipleEntriesMessageBoard"
import { UpsertPoolMessageMutationVariables } from "../../../../../__generated__/UpsertPoolMessageMutation"

const Footnote = styled.div`
  display: flex;
  align-items: center;
  svg {
    padding-right: ${pxToRem(5)};
    height: ${pxToRem(20)};
    width: ${pxToRem(20)};
  }
`

const oneMinute = 60 * 1000
const emptyArray = [] as never[]

const StyledMessagesContainer = styled.div`
  @media (min-width: ${pxToRem(800)}) {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
`
const MessagesWrapper = styled.div<{ isRoot: boolean }>`
  background-color: ${palette.white};
  box-shadow: 0 0 ${pxToRem(4)} 0 ${palette.gray3};
  padding: ${({ isRoot }) => (isRoot ? `${pxToRem(16)} 0 0` : `${pxToRem(16)} 0`)};
  border-radius: ${pxToRem(8)};
  margin: ${pxToRem(8)};
`
const MessagesBoardDescription = styled.div`
  background-color: ${palette.white};
  padding: ${pxToRem(16)} ${pxToRem(16)} ${pxToRem(8)};

  margin-bottom: ${pxToRem(16)};
  & > span {
    font-size: ${pxToRem(14)};
  }
`
const MessagesBoardTitle = styled.h1`
  font-style: nomal;
  font-weight: ${fontWeight.bold};
  font-size: ${pxToRem(20)};
  line-height: ${pxToRem(20)};
`

function PoolMessageBoardWrapper(props: TPoolRouteProps) {
  const { poolData, location } = props
  const { currentUser, poolId } = poolData
  const { first, after, goToPage } = useControls(props)
  const currentSearch = location.search
  const rootMessageId = getStringParam("rootMessageId", currentSearch)

  const variables: UpsertPoolMessageMutationVariables = {
    poolId,
    rootMessageId,
    body: "",
    htmlBody: "",
  }

  const poolMessageBoardQuery = useQuery<PoolMessageBoardQuery, PoolMessageBoardQueryVariables>(POOL_MESSAGE_BOARD_QUERY, {
    variables: {
      poolId,
      rootMessageId: rootMessageId || "",
      first,
      after,
      skipRootMessage: !rootMessageId,
    },
  })

  if (poolData.areaHasMultipleEntriesPerUser) {
    return (
      <StandardPoolPageContainer {...props}>
        <MultipleEntriesMessageBoard {...props} />
      </StandardPoolPageContainer>
    )
  }

  const gotoThread = (key, value) => {
    const search = setParam(currentSearch, key, value)
    const newLocation = Object.assign({}, props.location, { search })
    props.history.push(newLocation)
  }

  // const entry = poolMessageBoardQuery.data?.entry
  // const entries = (poolMessageBoardQuery.data?.entry.pool.entries.edges || emptyArray).map(edgeToNode)
  const rootMessage = poolMessageBoardQuery.data?.message
  const hasNextPage = poolMessageBoardQuery.data?.pool.messages.pageInfo.hasNextPage || false
  const total = poolMessageBoardQuery.data?.pool.messages?.pageInfo?.totalCount || 0

  const messages = (poolMessageBoardQuery.data?.pool.messages.edges || emptyArray).map(edgeToNode)
  const isLoading = !!poolMessageBoardQuery && poolMessageBoardQuery.networkStatus < 3
  // const categoryOptions = baseCategoryOptions.concat(entries.filter((e) => e.id !== entry?.id).map(toEntryOption))
  const lastMessageSentRecently = (messages.length && messages[messages.length - 1].sentAt * 1000 + 15 * oneMinute > Date.now()) || false
  // const participantEntries = (participantsSubsect || emptyArray)
  //   .map((eId) => entries.find((e) => e.id === eId))
  //   .filter((e) => !!e && e.id !== entry?.id)
  // const selectedCategory = participantEntries.map((x) => mapToId(x!)).join(participantsSubsectDivider) || category
  const onMount = undefined // unreadMessageCount ? () => console.log(`unreadMessageCount: ${unreadMessageCount}`) : undefined
  return (
    <StandardPoolPageContainer
      {...props}
      usesCustomSideCol={true}
      analyticsFeature="message boards"
      analyticsSubfeature={`"public" ${rootMessage ? "replies" : "post"}`}
      analyticsTitle={`Message Boards - Public Group - ${rootMessage ? "Replies" : "Post"}`}
      rightAction={
        <Footnote>
          <LockSvg color={palette.uiBlue1} />
          Indicates Private Messages
        </Footnote>
      }
    >
      <StyledMessagesContainer>
        <MessageFormProvider upsertPoolMessageMutationVariables={variables} apiRefresherFn={poolMessageBoardQuery.refetch} currentUser={currentUser}>
          <PoolInnerWithSideCol side={<div />}>
            <ApiRefresher
              onMount={onMount}
              refetchAfterMs={1000 * (lastMessageSentRecently ? 30 : 500)}
              query={poolMessageBoardQuery}
              networkStatus={poolMessageBoardQuery.networkStatus}
            />
            <MessagesWrapper isRoot={!rootMessageId}>
              <MessagesBoardDescription>
                <MessagesBoardTitle>Message Board</MessagesBoardTitle>
                <span>The Message Board is for group discussion. All postings will be CC'd to your group's email list</span>
              </MessagesBoardDescription>
              {hasNextPage && (
                <OpacityBtn type="button" as="button" onClick={() => goToPage(messages[messages.length - 1].id)}>
                  View Previous Page
                </OpacityBtn>
              )}
              {(isLoading && <LoadingView color="rgba(0,0,0,0.6)" />) || (
                <MessagesContainer
                  mkey={`${messages.map((m) => m.id).join(",")}`}
                  topicsUrl={props.match.url}
                  messages={messages}
                  isThreadDetails={!!rootMessageId}
                  goToThread={gotoThread}
                  totalCount={total}
                />
              )}
            </MessagesWrapper>
          </PoolInnerWithSideCol>
        </MessageFormProvider>
      </StyledMessagesContainer>
    </StandardPoolPageContainer>
  )
}

export default PoolMessageBoardWrapper
